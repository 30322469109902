<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div
            id="__BVID__700"
            class="tabs navtab-bg"
          >
            <div class="">
              <ul
                id="__BVID__700__BV_tab_controls_"
                role="tablist"
                class="nav nav-tabs"
              >
                <li
                  role="presentation"
                  class="nav-item"
                >
                  <a
                    id="__BVID__701___BV_tab_button__"
                    role="tab"
                    aria-selected="true"
                    aria-setsize="1"
                    aria-posinset="1"
                    href="#"
                    target="_self"
                    class="nav-link active bg-primary text-white"
                    aria-controls="__BVID__701"
                  >เลขท้าย</a>
                </li>
              </ul>
            </div>
            <div
              id="__BVID__700__BV_tab_container_"
              class="tab-content"
            >
              <div
                id="__BVID__701"
                role="tabpanel"
                aria-hidden="false"
                class="tab-pane active"
                aria-labelledby="__BVID__701___BV_tab_button__"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="table-responsive">
                      <table class="table table-bordered table-sm table-hover">
                        <thead class="thead-dark">
                          <tr>
                            <th
                              rowspan="2"
                              class="text-center align-middle"
                              style="width: 10rem;"
                            >
                              กลุ่มหวย
                            </th>
                            <th
                              rowspan="2"
                              class="text-center align-middle"
                              style="width: 10rem;"
                            >
                              ชนิดหวย
                            </th>
                            <th
                              rowspan="2"
                              class="text-center align-middle"
                            >
                              <div class="custom-control custom-checkbox">
                                <input
                                  id="checkbox-all"
                                  type="checkbox"
                                  name="checkbox-1"
                                  class="custom-control-input"
                                  value="true"
                                ><label
                                  for="checkbox-all"
                                  class="custom-control-label"
                                />
                              </div>
                            </th>
                            <th class="text-center">
                              วิ่งบน
                            </th>
                            <th class="text-center">
                              2 ตัวล่าง
                            </th>
                            <th class="text-center">
                              2 ตัวบน
                            </th>
                            <th class="text-center">
                              2 ตัวโต๊ด
                            </th>
                            <th class="text-center">
                              3 ตัวโต๊ด
                            </th>
                            <th class="text-center">
                              3 ตัวล่าง
                            </th>
                            <th class="text-center">
                              3 ตัวบน
                            </th>
                            <th class="text-center">
                              วิ่งล่าง
                            </th>
                            <th
                              rowspan="2"
                              class="text-center align-middle"
                              style="width: 7rem;"
                            >
                              <button
                                type="button"
                                class="btn btn-success btn-sm"
                              >บันทึก
                              </button>
                            </th>
                          </tr>
                          <tr>
                            <th class="text-center">
                              <input
                                type="number"
                                class="form-control form-control-sm"
                              >
                            </th>
                            <th class="text-center">
                              <input
                                type="number"
                                class="form-control form-control-sm"
                              >
                            </th>
                            <th class="text-center">
                              <input
                                type="number"
                                class="form-control form-control-sm"
                              >
                            </th>
                            <th class="text-center">
                              <input
                                type="number"
                                class="form-control form-control-sm"
                              >
                            </th>
                            <th class="text-center">
                              <input
                                type="number"
                                class="form-control form-control-sm"
                              >
                            </th>
                            <th class="text-center">
                              <input
                                type="number"
                                class="form-control form-control-sm"
                              >
                            </th>
                            <th class="text-center">
                              <input
                                type="number"
                                class="form-control form-control-sm"
                              >
                            </th>
                            <th class="text-center">
                              <input
                                type="number"
                                class="form-control form-control-sm"
                              >
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="mr-2 my-1">
                                <b>หวยรายวัน</b>
                              </div>
                            </td>
                            <td>
                              <div class="mr-2 my-1">
                                <b>หวยลาว TV</b>
                              </div>
                            </td>
                            <td class="text-center">
                              <div class="my-1 custom-control custom-checkbox">
                                <input
                                  id="LTS-1lr7aaiaCpPlUNcknxJsGsfiEZy743"
                                  type="checkbox"
                                  class="custom-control-input"
                                  value="LT-1lr7aaQJZDtEXLN3eAsUvjj0JCe912"
                                ><label
                                  for="LTS-1lr7aaiaCpPlUNcknxJsGsfiEZy743"
                                  class="custom-control-label"
                                />
                              </div>
                            </td>
                            <td class="text-center ">
                              <p class="my-1">
                                0
                              </p>
                            </td>
                            <td class="text-center ">
                              <p class="my-1">
                                1,000
                              </p>
                            </td>
                            <td class="text-center ">
                              <p class="my-1">
                                1,000
                              </p>
                            </td>
                            <td class="text-center ">
                              <p class="my-1">
                                0
                              </p>
                            </td>
                            <td class="text-center ">
                              <p class="my-1">
                                100
                              </p>
                            </td>
                            <td class="text-center ">
                              <p class="my-1">
                                0
                              </p>
                            </td>
                            <td class="text-center ">
                              <p class="my-1">
                                0
                              </p>
                            </td>
                            <td class="text-center ">
                              <p class="my-1">
                                0
                              </p>
                            </td>
                            <td class="text-center my-1">
                              <button
                                type="button"
                                class="btn btn-primary btn-sm"
                                @click="showModal"
                              >
                                แก้ไข
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal -->
    <b-modal
      ref="my-modal"
      title="แก้ไขตั้งค่ารับของ ตามชนิดหวย"
      ok-title="บันทึก"
      cancel-title="ยกเลิก"
    >
      <div class="modal-body">
        <div class="form-row">
          <fieldset class="form-group col-6">
            <legend
              tabindex="-1"
              class="bv-no-focus-ring col-form-label pt-0"
            >
              กลุ่มหวย
            </legend>
            <div
              tabindex="-1"
              role="group"
              class="bv-no-focus-ring"
            >
              <input
                type="text"
                readonly="readonly"
                class="form-control is-valid"
              >
              <div class="invalid-feedback">
                กรุณากรอกชื่อ
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group col-6">
            <legend
              tabindex="-1"
              class="bv-no-focus-ring col-form-label pt-0"
            >
              ชนิดหวย
            </legend>
            <div
              tabindex="-1"
              role="group"
              class="bv-no-focus-ring"
            >
              <input
                type="text"
                readonly="readonly"
                class="form-control is-valid"
              >
              <div class="invalid-feedback">
                กรุณากรอกชื่อ
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group col-12">
            <legend
              tabindex="-1"
              class="bv-no-focus-ring col-form-label pt-0"
            >
              การแทง
            </legend>
            <div
              tabindex="-1"
              role="group"
              class="bv-no-focus-ring"
            >
              <input
                type="text"
                readonly="readonly"
                class="form-control is-valid"
              >
              <div class="invalid-feedback">
                กรุณากรอกชื่อ
              </div>
            </div>
          </fieldset>
          <fieldset class="form-group col-12">
            <div
              tabindex="-1"
              role="group"
              class="bv-no-focus-ring"
            >
              <div class="table-responsive">
                <table
                  role="table"
                  class="table b-table"
                >
                  <thead
                    role="rowgroup"
                    class="thead-light"
                  >
                    <tr
                      role="row"
                      class=""
                    >
                      <th
                        role="columnheader"
                        scope="col"
                        class="text-center"
                      >
                        การแทง
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        class="text-center"
                      >
                        จำนวนเงินที่ถือสู้
                        <span class="text-danger">ต่อตัว</span>
                        <input
                          id="__BVID__420"
                          type="number"
                          min="0"
                          step="1"
                          class="form-control"
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <tr
                      role="row"
                      class=""
                    >
                      <td
                        role="cell"
                        class=""
                      >
                        3 ตัวบน
                      </td>
                      <td
                        role="cell"
                        class=""
                      >
                        <input
                          id="__BVID__425"
                          type="number"
                          class="form-control is-valid"
                        >
                      </td>
                    </tr>
                    <tr
                      role="row"
                      class=""
                    >
                      <td
                        role="cell"
                        class=""
                      >
                        3 ตัวล่าง
                      </td>
                      <td
                        role="cell"
                        class=""
                      >
                        <input
                          id="__BVID__429"
                          type="number"
                          class="form-control is-valid"
                        >
                      </td>
                    </tr>
                    <tr
                      role="row"
                      class=""
                    >
                      <td
                        role="cell"
                        class=""
                      >
                        3 ตัวโต๊ด
                      </td>
                      <td
                        role="cell"
                        class=""
                      >
                        <input
                          id="__BVID__433"
                          type="number"
                          class="form-control is-valid"
                        >
                      </td>
                    </tr>
                    <tr
                      role="row"
                      class=""
                    >
                      <td
                        role="cell"
                        class=""
                      >
                        2 ตัวบน
                      </td>
                      <td
                        role="cell"
                        class=""
                      >
                        <input
                          id="__BVID__437"
                          type="number"
                          class="form-control is-valid"
                        >
                      </td>
                    </tr>
                    <tr
                      role="row"
                      class=""
                    >
                      <td
                        role="cell"
                        class=""
                      >
                        2 ตัวล่าง
                      </td>
                      <td
                        role="cell"
                        class=""
                      >
                        <input
                          id="__BVID__441"
                          type="number"
                          class="form-control is-valid"
                        >
                      </td>
                    </tr>
                    <tr
                      role="row"
                      class=""
                    >
                      <td
                        role="cell"
                        class=""
                      >
                        วิ่งบน
                      </td>
                      <td
                        role="cell"
                        class=""
                      >
                        <input
                          type="number"
                          class="form-control is-valid"
                        >
                      </td>
                    </tr>
                    <tr
                      role="row"
                      class=""
                    >
                      <td
                        role="cell"
                        class=""
                      >
                        วิ่งล่าง
                      </td>
                      <td
                        role="cell"
                        class=""
                      >
                        <input
                          type="number"
                          class="form-control is-valid"
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="float-right">
                หากไม่ต้องการถือสู้ในประเภทใด ๆ ให้ใส่ 0
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
  },
}
</script>

<style>

</style>
